import { validateSchema } from "@/modules/core/helpers/validateSchema";
import { statusSchema } from "@/modules/core/models/Status";

const exceptionRequestSchema = {
  type: "object",
  properties: {
    id: { type: "number" },
    parecer_em: { oneOf: [{ type: "null" }, { type: "string" }] },
    parecer_por: { oneOf: [{ type: "null" }, { type: "string" }] },
    produtor: { type: "string" },
    produtor_id: { type: "number" },
    solicitado_em: { type: "string" },
    solicitante: { type: "string" },
    uf: { type: "string" },
    status: statusSchema,
    tipo_produtor: {
      type: "object",
      properties: {
        id: { type: "string" },
        descricao: { type: "string" }
      },
      required: ["id", "descricao"],
      additionalProperties: false
    }
  },
  required: [
    "id",
    "parecer_em",
    "parecer_por",
    "produtor",
    "produtor_id",
    "solicitado_em",
    "solicitante",
    "status",
    "tipo_produtor"
  ],
  additionalProperties: false
};

export class ExceptionRequest {
  constructor(object) {
    validateSchema(exceptionRequestSchema, object);
    Object.assign(this, object);
  }
}
