import { validateSchema } from "../helpers/validateSchema";

export const statusSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    descricao: { type: "string" }
  },
  required: ["id", "descricao"],
  additionalProperties: false
};

export class Status {
  constructor(object) {
    validateSchema(statusSchema, object);
    Object.assign(this, object);
  }
}
