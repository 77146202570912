import Ajv from "ajv";

export function validateSchema(schema, data) {
  const ajv = new Ajv();

  const validate = ajv.compile(schema);
  const valid = validate(data);

  if (!valid) {
    console.error(validate.errors);
    throw new Error("Invalid schema");
  }
}
