<template>
  <div v-if="modalState">
    <v-dialog
      v-model="modalState"
      :persistent="true"
      :retain-focus="false"
      origin="center left"
      width="850"
      scrollable
    >
      <v-card class="modal">
        <v-card-text>
          <h3 class="my-4 primary--text">
            {{ $t("labels.detail_producer_or_producer_group") }}
          </h3>

          <producer-or-group-detail class="my-4" :state="detail" />
        </v-card-text>

        <v-card-text>
          <h3 class="my-4 primary--text">
            {{ $t("labels.detail_request") }}
          </h3>

          <detail-exception-request :detail="detail" @updated="handleUpdated" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-center align-center">
          <v-btn text color="primary" @click="close">{{
            $t("labels.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProducerOrGroupDetail from "@/modules/inviteds/views/components/ProducerOrGroupDetail";
import DetailExceptionRequest from "@/modules/exception-request/views/components/DetailExceptionRequest";

import { ExceptionRequest } from "@/modules/exception-request/models/ExceptionRequest";
import { ExceptionRequestDetail } from "@/modules/exception-request/models/ExceptionRequestDetail";

import { mapActions } from "vuex";

export default {
  name: "DialogDetailExceptionRequest",

  components: {
    ProducerOrGroupDetail,
    DetailExceptionRequest
  },

  data: () => ({
    modalState: false,

    exceptionRequest: {},
    detail: {}
  }),

  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("exceptionRequest", ["fetchExceptionRequestsSbrhvi"]),

    open(exceptionRequest) {
      this.exceptionRequest = new ExceptionRequest(exceptionRequest);
      this.fetchData();
    },

    handleUpdated() {
      this.fetchData();
      this.$emit("updated");
    },

    fetchData() {
      this.callFetchExceptionRequestSbrhvi(data => {
        this.detail = new ExceptionRequestDetail(data);
        this.modalState = true;
      });
    },

    async callFetchExceptionRequestSbrhvi(fnOnSuccess) {
      try {
        const data = await this.fetchExceptionRequestsSbrhvi(
          this.exceptionRequest.id
        );

        fnOnSuccess(data);
      } catch (error) {
        this.toggleSnackbar({
          text: error?.response?.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    close() {
      this.modalState = false;
      this.$emit("modalClose");
    }
  }
};
</script>

<style lang="scss" scoped></style>
