<template>
  <div v-if="modalState">
    <v-dialog
      v-model="modalState"
      :persistent="true"
      :retain-focus="false"
      origin="center left"
      width="600"
      scrollable
    >
      <v-card class="modal">
        <v-card-title class="primary--text">
          {{ $t("text.request_review_status") }}
        </v-card-title>

        <v-divider />

        <v-card-text class="py-10">
          <div class="pb-5">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.current_status") }}
            </p>
            <p class="ma-0 primary-text--text">
              <strong>
                <status-component :value="status.id">
                  {{ status.descricao }}
                </status-component>
              </strong>
            </p>
          </div>

          <v-form ref="form" v-model="form.isValid">
            <v-row cols="12">
              <v-col>
                <v-textarea
                  v-model="form.data.justification"
                  dense
                  :rules="form.rules.justification"
                  outlined
                  :label="$t('labels.reason_request')"
                />
              </v-col>
            </v-row>

            <v-row cols="12" class="ma-0">
              <v-checkbox
                v-model="form.data.acceptTerms"
                :rules="form.rules.acceptTerms"
                :label="$t('text.terms_accepted_request_status_review')"
              />
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-layout justify-end>
            <v-btn @click="close" class="mr-5" color="#FFF">{{
              $t("buttons.close")
            }}</v-btn>
            <v-btn
              type="submit"
              :disabled="!form.isValid"
              color="primary"
              @click="handleConfirm"
              >{{ $t("buttons.send_request") }}</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StatusComponent from "@/modules/inviteds/views/components/Status";

import { Status } from "@/modules/core/models/Status";

export default {
  name: "DialogRequestStatusReview",

  components: {
    StatusComponent
  },

  props: {
    status: {
      type: Status,
      required: true
    },
    fnConfirm: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    modalState: false,
    form: {
      isValid: false,
      data: {
        justification: null,
        acceptTerms: false
      },
      rules: {
        justification: [
          value => !!value || "É necessário informar o motivo da solicitação"
        ],
        acceptTerms: [value => !!value || "É necessário confirmar esta ação"]
      }
    }
  }),

  methods: {
    open() {
      this.modalState = true;
    },

    close() {
      this.modalState = false;
      this.$emit("closed");
    },

    handleConfirm() {
      this.close();
      this.fnConfirm(this.form.data);
    }
  }
};
</script>

<style lang="scss" scoped></style>
