<template>
  <v-card
    class="pa-4 mt-5 card-content d-flex flex-column justify-space-between"
  >
    <div>
      <v-row class="ma-0">
        <v-col cols="12" md="2">
          <v-text-field
            dense
            v-model.lazy="filters.produtor"
            append-icon="mdi-magnify"
            outlined
            color="primary"
            :label="$t('labels.productor_or_group')"
            type="text"
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-select
            v-model.lazy="filters.tipo"
            dense
            :items="producerTypes"
            item-text="name"
            item-value="id"
            outlined
            :color="'primary'"
            :label="$t('labels.type')"
          />
        </v-col>

        <v-col cols="12" md="1" v-if="profile.canSeeFilterAndColumnUf">
          <v-select
            v-model.lazy="filters.uf"
            dense
            :items="ufs"
            item-text="name"
            item-value="abbreviation"
            outlined
            :color="'primary'"
            :label="$t('labels.uf')"
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field
            v-model.lazy="filters.cpf_cnpj"
            dense
            outlined
            color="primary"
            :label="$t('labels.cpf_cnpj_productor_group')"
            type="text"
            v-mask="masks.cpfOrCnpj"
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-select
            v-model.lazy="filters.status"
            dense
            :items="exceptionRequestsStatus"
            item-text="name"
            item-value="id"
            outlined
            :color="'primary'"
            :label="$t('labels.status', { certificationType: '' })"
          />
        </v-col>

        <v-col cols="12" md="3">
          <div style="height: 60%;" class="d-flex justify-end align-end">
            <v-btn
              color="primary"
              depressed
              width="150"
              class="mr-5"
              @click="search"
              >Pesquisar</v-btn
            >
            <v-btn color="white" @click="clearFilters" depressed width="150"
              >Limpar</v-btn
            >
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-alert class="mt-3" type="info">
        {{ this.$t("text.text_info_new_exception_requests") }}
      </v-alert>

      <div class="my-5">
        <v-btn
          v-if="profile.canCreateExceptionRequest"
          color="primary"
          @click="handleOpenNewRequest"
          depressed
          class="mr-5"
          >{{
            $t("buttons.exception_request", { program: "ABR/SBRHVI" })
          }}</v-btn
        >
      </div>

      <h2>{{ $t("text.mandatory_exception_requests") }}</h2>

      <v-data-table
        :headers="headers"
        :items="exceptionRequests"
        hide-default-footer
        :no-data-text="$t('text.nenhum_registro_encontrado_tabelas')"
      >
        <!-- Custom Status -->
        <template v-slot:[`item.status`]="{ item }">
          <status :value="item.status.id">
            {{ item.status.descricao }}
          </status>
        </template>

        <!-- Detail -->-->
        <template v-slot:[`item.id`]="{ item }">
          <btn-link @click="handleOpenDetail(item)">Detalhes</btn-link>
        </template>
      </v-data-table>
    </div>
    <div>
      <v-row class="mt-8" align="center">
        <v-col :cols="12" md="3" align-self="center">
          <span class="label-footer">
            {{
              `${$t("labels.show_register", {
                totalPerPage: tableInfo.totalPerPage,
                record: $tc("labels.record", tableInfo.totalPerPage),
                totalItems: tableInfo.totalItems
              })}`
            }}
          </span>
        </v-col>
        <v-col :cols="12" md="6">
          <v-pagination
            v-model="tableInfo.page"
            :length="tableInfo.totalPages"
          ></v-pagination>
        </v-col>
        <v-col :cols="12" md="3">
          <v-select
            v-model="tableInfo.limit"
            :items="perPageOptions"
            outlined
            color="primary-color-1"
          ></v-select>
        </v-col>
      </v-row>
      <dialog-detail-exception-request
        ref="dialogExceptionRequest"
        @updated="getExceptionRequests"
      />
      <dialog-new-exception-request
        ref="dialogNewExceptionRequest"
        :key="`dialog-new-exception-request-${dialogNewExceptionRequestKey}`"
        :fnAfterCreate="getExceptionRequests"
        @closed="handleCloseNewExceptionRequestDialog"
      />
    </div>
  </v-card>
</template>

<script>
import MasksMixin from "@/modules/core/mixins/masksMixin";
import Status from "@/modules/inviteds/views/components/Status";
import BtnLink from "@/modules/core/views/components/BtnLink";
import DialogDetailExceptionRequest from "@/modules/exception-request/views/components/DialogDetailExceptionRequest";
import DialogNewExceptionRequest from "@/modules/exception-request/views/components/DialogNewExceptionRequest";

import { ExceptionRequestStatus } from "@/modules/exception-request/enums/exception-request-status.enum";
import { ProducerType } from "@/modules/core/enums/producer-type.enum";
import { Profile } from "@/modules/inviteds/enums/profile.enum";
import { uf } from "@/modules/core/enums/uf.enum";

import { mapActions, mapGetters } from "vuex";
import { pickBy, identity } from "lodash";

export default {
  name: "ExceptionRequests",

  components: {
    Status,
    BtnLink,
    DialogDetailExceptionRequest,
    DialogNewExceptionRequest
  },

  mixins: [MasksMixin],

  data: () => ({
    profiles: {
      [Profile.STATE.id]: {
        canCreateExceptionRequest: true,
        canSeeFilterAndColumnUf: false
      },
      [Profile.ABRAPA.id]: {
        canCreateExceptionRequest: false,
        canSeeFilterAndColumnUf: true
      }
    },

    exceptionRequests: [],

    tableInfo: {
      page: 1,
      totalItems: 0,
      totalPerPage: 0,
      totalPages: 0,
      limit: 10
    },

    filters: {},

    perPageOptions: [
      { value: 10, text: "10 itens/página" },
      { value: 25, text: "25 itens/página" },
      { value: 50, text: "50 itens/página" },
      { value: 100, text: "100 itens/página" }
    ],

    dialogNewExceptionRequestKey: 1
  }),

  computed: {
    ...mapGetters({
      userProfile: "inviteds/getUserProfile"
    }),

    profile() {
      return this.profiles[this.userProfile.id] ?? [];
    },

    headers() {
      const columns = [
        {
          text: this.$t("labels.productor_or_group"),
          value: "produtor"
        },
        {
          text: this.$t("labels.type"),
          value: "tipo_produtor.descricao"
        },
        {
          text: this.$t("labels.uf"),
          value: "uf"
        },
        {
          text: this.$t("labels.requester"),
          value: "solicitante"
        },
        {
          text: this.$t("labels.datetime_type", { type: "Solicitação" }),
          value: "solicitado_em"
        },
        {
          text: this.$t("labels.status", { certificationType: "" }),
          value: "status"
        },
        {
          text: this.$t("labels.responsible_opnion"),
          value: "parecer_por"
        },
        {
          text: this.$t("labels.datetime_type", { type: "Parecer" }),
          value: "parecer_em"
        },
        { value: "id" }
      ];

      if (!this.profile.canSeeFilterAndColumnUf) {
        return columns.filter(column => column.value !== "uf");
      }

      return columns;
    },

    producerTypes() {
      return [{ id: "", name: "Todos" }, ...Object.values(ProducerType)];
    },

    exceptionRequestsStatus() {
      return [
        { id: "", name: "Todos" },
        ...Object.values(ExceptionRequestStatus)
      ];
    },

    ufs() {
      return [
        {
          abbreviation: "",
          name: "Todos"
        },
        ...uf
      ];
    }
  },

  mounted() {
    this.getExceptionRequests();
  },

  watch: {
    "tableInfo.limit": function() {
      this.tableInfo.page = 1;
      this.getExceptionRequests();
    },

    "tableInfo.page": function() {
      this.getExceptionRequests();
    },

    "answerInviteState.activate": function(newVal) {
      if (newVal) {
        this.answerInviteKey++;
      }
    }
  },

  methods: {
    ...mapActions("exceptionRequest", ["fetchExceptionRequestsSbrhvi"]),
    ...mapActions("invited", ["fetchInviteByInvitedIdAndType"]),
    ...mapActions(["toggleSnackbar"]),

    async search() {
      this.tableInfo.page = 1;
      await this.getExceptionRequests();
    },

    async getExceptionRequests() {
      try {
        const data = await this.fetchExceptionRequestsSbrhvi(
          this.getPayloadData()
        );

        this.exceptionRequests = this.formatData(data.results);

        this.setTableInfo({
          totalItems: data.count,
          totalPages: data.total_pages,
          totalPerPage: data.per_page
        });
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    getPayloadData() {
      const cpf_cnpj = this.filters?.cpf_cnpj?.replace(/\D/g, "") ?? null;
      const filters = { ...filters, cpf_cnpj };

      return {
        ...pickBy(this.filters, identity),
        page: this.tableInfo.page,
        limit: this.tableInfo.limit
      };
    },

    formatData(exceptionRequests) {
      return exceptionRequests.map(exceptionRequest => {
        return {
          ...exceptionRequest,
          parecer_por:
            exceptionRequest.parecer_por ?? this.$t("text.field_not_found"),
          parecer_em:
            exceptionRequest.parecer_em ?? this.$t("text.field_not_found")
        };
      });
    },

    setTableInfo(meta) {
      this.tableInfo = {
        ...this.tableInfo,
        ...meta
      };
    },

    setFilter(key, value) {
      this.filters[key] = value;
    },

    clearFilters() {
      this.filters = {};
      this.fetchExceptionRequestsSbrhvi();
    },

    handleOpenDetail(item) {
      this.$refs.dialogExceptionRequest.open(item);
    },

    handleOpenNewRequest() {
      this.$refs.dialogNewExceptionRequest.open();
    },

    handleCloseNewExceptionRequestDialog() {
      this.dialogNewExceptionRequestKey++;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-content {
  min-width: 400px;
  min-height: 1000px;
}
</style>
