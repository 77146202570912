<template>
  <v-layout d-flex flex-column class="fill">
    <v-row dense>
      <v-col cols="12" md="3">
        <div class="d-flex flex-column">
          <p class="ma-0 secondary-text--text">{{ $t("labels.requester") }}</p>
          <p class="ma-0 primary-text--text">
            <strong>{{ detail.solicitante }}</strong>
          </p>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex flex-column">
          <p class="ma-0 secondary-text--text">
            {{ $t("labels.datetime_type", { type: "Solicitação" }) }}
          </p>
          <div>
            <span class="ma-0 primary-text--text">
              {{ detail.solicitado_em }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex flex-column">
          <p class="ma-0 secondary-text--text">
            {{ $t("labels.status", { certificationType: "" }) }}
          </p>
          <div>
            <status-component :value="detail.status.id">
              {{ detail.status.descricao }}
            </status-component>
          </div>
          <div v-if="canSeeCancelRequestButton" class="mt-2">
            <v-btn
              outlined
              small
              color="refuse"
              @click="prepareToCancelExceptionRequest"
            >
              {{ $t("buttons.cancel_request") }}
            </v-btn>
          </div>
          <div v-if="detail.pode_revisar_status" class="mt-2">
            <v-btn outlined small color="accept" @click="prepareToReviewStatus">
              {{ $t("buttons.request_review_status") }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row dense class="mt-3">
      <v-col cols="12" md="3">
        <div class="d-flex flex-column">
          <p class="ma-0 secondary-text--text">
            {{ $t("labels.accountable_notion") }}
          </p>
          <p class="ma-0 primary-text--text">
            <strong>
              {{ detail.parecer_por || $t("text.field_not_found") }}
            </strong>
          </p>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex flex-column">
          <p class="ma-0 secondary-text--text">
            {{ $t("labels.datetime_type", { type: "Parecer" }) }}
          </p>
          <div>
            <span class="ma-0 primary-text--text">
              {{ detail.parecer_em || $t("text.field_not_found") }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <div class="d-flex flex-column">
          <p class="ma-0 secondary-text--text">
            {{ $t("labels.reason_request") }}
          </p>
          <div>
            <span class="ma-0 primary-text--text">
              {{ detail.motivo || $t("text.field_not_found") }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="end" class="mt-2">
      <v-col cols="auto" v-if="canSeeReproveRequestButton">
        <div>
          <v-btn
            small
            color="refuse"
            :disabled="detail.status.id !== ExceptionRequestStatus.IN_REVIEW.id"
            class="white--text"
            @click="
              () =>
                prepareToSendOpinionExceptionRequest(
                  $t('buttons.reprove_exception_request'),
                  ExceptionRequestStatus.REPROVED.id
                )
            "
          >
            {{ $t("buttons.reprove_exception_request") }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="auto" v-if="canSeeApproveRequestButton">
        <div>
          <v-btn
            small
            color="accept"
            :disabled="detail.status.id !== ExceptionRequestStatus.IN_REVIEW.id"
            class="white--text"
            @click="
              () =>
                prepareToSendOpinionExceptionRequest(
                  $t('buttons.aprove_exception_request'),
                  ExceptionRequestStatus.APPROVED.id
                )
            "
          >
            {{ $t("buttons.aprove_exception_request") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <dialog-request-status-review
      ref="dialogRequestStatusReview"
      :key="`dialog-request-status-review-${dialogRequestStatusReviewKey}`"
      :status="status"
      :fnConfirm="handleConfirmRequestReviewStatus"
      @closed="handleCloseRequestReviewStatus"
    />
  </v-layout>
</template>

<script>
import StatusComponent from "@/modules/inviteds/views/components/Status";
import DialogRequestStatusReview from "@/modules/exception-request/views/components/DialogRequestStatusReview";
import { ExceptionRequestDetail } from "@/modules/exception-request/models/ExceptionRequestDetail";
import {
  ExceptionRequestStatus,
  getExceptionRequestStatusMessageById
} from "@/modules/exception-request/enums/exception-request-status.enum";
import { Profile } from "@/modules/inviteds/enums/profile.enum";

import { Status } from "@/modules/core/models/Status";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "DetailExceptionRequest",

  props: {
    detail: {
      type: ExceptionRequestDetail,
      required: true
    }
  },

  components: {
    StatusComponent,
    DialogRequestStatusReview
  },

  data: () => ({
    dialogRequestStatusReviewKey: 1,
    ExceptionRequestStatus,

    profiles: {
      [Profile.STATE.id]: {
        canSeeReproveRequestButton: false,
        canSeeApproveRequestButton: false
      },
      [Profile.ABRAPA.id]: {
        canSeeReproveRequestButton: true,
        canSeeApproveRequestButton: true
      }
    }
  }),

  computed: {
    ...mapGetters({
      userProfile: "inviteds/getUserProfile"
    }),

    status() {
      return new Status(this.detail.status);
    },

    profile() {
      return this.profiles[this.userProfile.id];
    },

    isInReview() {
      return this.detail?.status?.id === ExceptionRequestStatus.IN_REVIEW.id;
    },

    canSeeReproveRequestButton() {
      return this.isInReview && this.profile.canSeeReproveRequestButton;
    },

    canSeeApproveRequestButton() {
      return this.isInReview && this.profile.canSeeApproveRequestButton;
    },

    canSeeCancelRequestButton() {
      return this.isInReview && this.detail.pode_cancelar;
    }
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),

    ...mapActions("exceptionRequest", [
      "cancelExceptionRequestSbrhvi",
      "opinionExceptionRequestSbrhvi",
      "sendNotionReview"
    ]),

    prepareToSendOpinionExceptionRequest(action, opinion) {
      const message = this.$t("text.confirm_send_opinion_exception_request", {
        action
      });
      this.$confirmDialog.open(message, () =>
        this.handleSendOpinionExceptionRequest(opinion)
      );
    },

    getSuccessMessageByOpinion(opinion) {
      return this.$t("text.message_request_success_of_type", {
        type: getExceptionRequestStatusMessageById(opinion)
      });
    },

    async handleSendOpinionExceptionRequest(opinion) {
      try {
        const response = await this.opinionExceptionRequestSbrhvi({
          id: this.detail.id,
          body: {
            status: opinion
          }
        });

        this.toggleSnackbar({
          text: response?.message || this.getSuccessMessageByOpinion(opinion),
          type: "success"
        });

        this.$emit("updated");
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    prepareToCancelExceptionRequest() {
      const message = this.$t("text.confirm_cancel_exception_request");
      this.$confirmDialog.open(
        message,
        this.handleConfirmCancelExceptionRequest
      );
    },

    async handleConfirmCancelExceptionRequest() {
      try {
        const response = await this.cancelExceptionRequestSbrhvi(
          this.detail.id
        );

        this.toggleSnackbar({
          text:
            response?.message ||
            this.getSuccessMessageByOpinion(ExceptionRequestStatus.CANCELED.id),
          type: "success"
        });

        this.$emit("updated");
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    prepareToReviewStatus() {
      this.$refs.dialogRequestStatusReview.open();
    },

    async handleConfirmRequestReviewStatus(data) {
      try {
        const response = await this.sendNotionReview({
          id: this.detail.id,
          body: {
            motivo: data.justification
          }
        });

        this.toggleSnackbar({
          text:
            response?.message ?? this.$t("text.message_review_request_success"),
          type: "success"
        });

        this.$emit("updated");
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    handleCloseRequestReviewStatus() {
      this.dialogRequestStatusReviewKey++;
    }
  }
};
</script>

<style></style>
