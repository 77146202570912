<template>
  <div v-if="modalState">
    <v-dialog
      v-model="modalState"
      :persistent="true"
      :retain-focus="false"
      origin="center left"
      width="950"
      scrollable
    >
      <v-form ref="form" v-model="form.isValid">
        <v-card>
          <v-card-title class="primary--text">
            {{ $t("labels.new_exception_request_title") }}
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h3 class="primary-text--text">
                  {{ $t("text.select_type_of_new_exception_request") }}
                </h3>
              </v-col>
            </v-row>

            <v-row class="mt-1">
              <v-col cols="12" md="3">
                <v-btn
                  :outlined="mode.type !== ProducerType.PRODUCER.id"
                  block
                  color="primary"
                  @click="selectType(ProducerType.PRODUCER.id)"
                >
                  <span>{{ $t("labels.producer") }}</span>
                </v-btn>
              </v-col>
              <v-col cols="12" md="5">
                <v-btn
                  :outlined="mode.type !== ProducerType.GROUP.id"
                  block
                  color="primary"
                  @click="selectType(ProducerType.GROUP.id)"
                >
                  <span>{{ $t("labels.producer_group") }}</span>
                </v-btn>
              </v-col>
            </v-row>

            <v-row dense class="mt-2">
              <v-col cols="12">
                <v-alert type="info">
                  {{ this.$t("text.info_exception_request_effect") }}
                </v-alert>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <h3 class="primary--text">
                  {{ $t(mode.titleSelect) }}
                </h3>
              </v-col>
            </v-row>

            <v-row dense v-if="mode.type">
              <v-col dense cols="10">
                <v-text-field
                  @input="debounceInput"
                  v-bind:value="searchInput"
                  outlined
                  dense
                  :placeholder="$t(mode.searchPlaceholder)"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-alert type="info">
                  {{
                    this.$t("text.info_exception_request_producer_not_found")
                  }}
                </v-alert>
              </v-col>
            </v-row>

            <v-data-table
              v-if="canSeeTable"
              :items="items"
              :headers="mode.headers"
              hide-default-footer
              :items-per-page="1000"
            >
              <template v-slot:[`item.id`]="{ item }">
                <v-btn
                  class="ma-2"
                  outlined
                  small
                  width="165"
                  :color="isSelected(item) ? 'error' : 'primary'"
                  @click="setSelectedProducerOrGroup(item)"
                >
                  {{
                    isSelected(item)
                      ? $t("buttons.cancel_selection")
                      : $t("buttons.select")
                  }}
                </v-btn>
              </template>
              <template v-slot:[`body.append`]="{ headers }">
                <tr>
                  <td :colspan="headers.length" style="height: 0;">
                    <infinite-loading spinner="waveDots" @infinite="loadMore">
                      <div slot="no-more"></div>
                      <div slot="no-results"></div>
                    </infinite-loading>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>

          <v-divider />

          <v-card-actions v-if="form.data.selectedProducerOrProducerGroup">
            <v-row class="ma-0 pa-0">
              <v-col cols="12">
                <p class="primary--text">
                  {{ $t("labels.reason_request") }}
                </p>
                <v-textarea
                  ref="textareaJustification"
                  v-model="form.data.justification"
                  dense
                  :rules="form.rules.justification"
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-layout justify-end>
              <v-btn @click="close" class="mr-5" color="#FFF">{{
                $t("buttons.close")
              }}</v-btn>
              <v-btn
                type="button"
                color="primary"
                :disabled="formInvalid"
                @click="handleConfirm"
                >{{ $t("buttons.send_request") }}</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { ProducerType } from "@/modules/core/enums/producer-type.enum";
import { debounce } from "lodash";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "DialogNewExceptionRequest",

  props: {
    fnAfterCreate: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    modalState: false,

    form: {
      isValid: false,
      data: {
        type: undefined,
        selectedProducerOrProducerGroup: undefined,
        justification: undefined
      },
      rules: {
        justification: [
          value => !!value || "É necessário informar o motivo da solicitação"
        ]
      }
    },

    ProducerType: ProducerType,

    searchInput: "",
    page: 1,
    limit: 15,
    next: null,
    loadingByInfiniteLoading: false,

    mode: {
      type: null,
      titleSelect: null
    },

    types: {
      [ProducerType.PRODUCER.id]: {
        type: ProducerType.PRODUCER.id,
        titleSelect: "text.titulo_selecione_produtor",
        searchPlaceholder: "labels.search_producer",
        dataNotFound: "text.nenhum_registro_encontrado_por_tipo",
        label: "produtor",
        headers: [
          {
            text: "Produtor/Grupo",
            align: "start",
            value: "nome"
          },
          { text: "CPF/CNPJ", value: "cpf_cnpj" },
          { text: "E-mail", value: "email" },
          { value: "id" }
        ]
      },
      [ProducerType.GROUP.id]: {
        type: ProducerType.GROUP.id,
        titleSelect: "text.titulo_selecione_grupo_produtor",
        searchPlaceholder: "labels.search_producer_group",
        dataNotFound: "text.nenhum_registro_encontrado_por_tipo",
        label: "grupo de produtores",
        headers: [
          {
            text: "Grupo de Produtores",
            align: "start",
            value: "nome"
          },
          { text: "Composição", value: "composicao" },
          { value: "id" }
        ]
      }
    },

    items: []
  }),

  watch: {
    async searchInput() {
      if (!this.hasSearchText()) {
        this.items = [];
        return;
      }
      this.page = 1;
      this.items = await this.fetchData();
    }
  },

  computed: {
    ...mapGetters({
      isLoading: "isLoading"
    }),

    canSeeTable() {
      return (
        this.hasSearchText() &&
        (this.loadingByInfiniteLoading || !this.isLoading) &&
        this.items?.length
      );
    },

    canSeeDataNotFound() {
      return this.hasSearchText() && !this.isLoading && !this.items?.length;
    },

    formInvalid() {
      return (
        !this.form.isValid ||
        !this.form.data?.selectedProducerOrProducerGroup?.id
      );
    }
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("exceptionRequest", [
      "createExceptionRequestSbrhvi",
      "getProducers",
      "getProducersGroups"
    ]),

    open() {
      this.modalState = true;
    },

    close() {
      this.modalState = false;
      this.$emit("closed");
    },

    isSelected(producerOrGroup) {
      return (
        this.form.data?.selectedProducerOrProducerGroup?.id ===
        producerOrGroup.id
      );
    },

    setSelectedProducerOrGroup(producerOrGroup) {
      if (this.isSelected(producerOrGroup)) {
        return (this.form.data.selectedProducerOrProducerGroup = undefined);
      }
      this.form.data.selectedProducerOrProducerGroup = producerOrGroup;

      this.$nextTick(() => {
        this.$refs.textareaJustification.focus();
      });
    },

    hasSearchText(searchValue) {
      return !!this.searchInput && !!this.searchInput.length;
    },

    selectType(type) {
      if (
        !Object.values(ProducerType).some(
          producerType => producerType.id === type
        )
      ) {
        throw new Error("Invalid selected type");
      }
      this.items = [];
      this.searchInput = "";
      this.mode = this.types[type];
      this.form.data.type = this.mode.type;
    },

    debounceInput: debounce(async function(value) {
      this.searchInput = value;
    }, 800),

    async fetchData() {
      if (this.mode.type === ProducerType.PRODUCER.id) {
        return await this.fetchProducers();
      }
      return await this.fetchProducersGroup();
    },

    async fetchProducers() {
      return await this.callFetchData(async () => {
        const { results, next } = await this.getProducers({
          buscar: this.searchInput,
          page: this.page,
          limit: this.limit
        });
        this.next = next;
        return results;
      });
    },

    async fetchProducersGroup() {
      return await this.callFetchData(async () => {
        const { results, next } = await this.getProducersGroups({
          nome: this.searchInput,
          page: this.page,
          limit: this.limit
        });
        this.next = next;
        return results;
      });
    },

    async callFetchData(fn) {
      try {
        return await fn();
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    async handleConfirm() {
      this.close();
      try {
        const payload = this.formatPayload();
        const response = await this.createExceptionRequestSbrhvi(payload);
        this.toggleSnackbar({
          text:
            response?.message ??
            this.$t("text.exception_request_register_success"),
          type: "success"
        });
        this.fnAfterCreate();
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    formatPayload() {
      return {
        produtor_id: this.form.data.selectedProducerOrProducerGroup.id,
        tipo_produtor: this.form.data.type,
        motivo: this.form.data.justification
      };
    },

    async loadMore($state) {
      if (!this.next) {
        $state.complete();
        return;
      }

      try {
        this.loadingByInfiniteLoading = true;
        this.page++;
        const data = await this.fetchData();
        this.items = [...this.items, ...data];
      } catch (error) {
        this.next = false;
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      } finally {
        this.loadingByInfiniteLoading = false;
        $state.loaded();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.new-exception-table {
  max-height: 400px;
  overflow-y: auto;
}
</style>
