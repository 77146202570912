import { validateSchema } from "@/modules/core/helpers/validateSchema";

const exceptionRequestDetailSchema = {
  type: "object",
  properties: {
    id: { type: "number" },
    cpf_cnpj: { oneOf: [{ type: "null" }, { type: "string" }] },
    email: { type: "string" },
    parecer_em: { oneOf: [{ type: "null" }, { type: "string" }] },
    parecer_por: { oneOf: [{ type: "null" }, { type: "string" }] },
    pode_cancelar: { type: "boolean" },
    pode_revisar_status: { type: "boolean" },
    produtor: { type: "string" },
    produtor_id: { type: "number" },
    solicitado_em: { type: "string" },
    solicitante: { type: "string" },
    uf: { type: "string" },
    composicao: {
      type: "array"
    },
    motivo: { type: "string" },
    status: {
      type: "object",
      properties: {
        id: { type: "string" },
        descricao: { type: "string" }
      },
      required: ["id", "descricao"],
      additionalProperties: false
    },
    tipo_produtor: {
      type: "object",
      properties: {
        id: { type: "string" },
        descricao: { type: "string" }
      },
      required: ["id", "descricao"],
      additionalProperties: false
    }
  },
  required: [
    "id",
    "cpf_cnpj",
    "email",
    "parecer_em",
    "parecer_por",
    "pode_cancelar",
    "pode_revisar_status",
    "produtor",
    "solicitado_em",
    "solicitante",
    "status",
    "tipo_produtor"
  ],
  additionalProperties: false
};

export class ExceptionRequestDetail {
  constructor(object) {
    validateSchema(exceptionRequestDetailSchema, object);
    Object.assign(this, object);
  }
}
